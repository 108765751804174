import React from 'react';

import TermsCat from 'assets/terms-cat.png';
import TermsDog from 'assets/terms-dog.png';

const Step4 = (props) => {

  return (
    <div className={props.step===4?"columns is-centered tab-content active":"columns is-centered tab-content"} id="tab1-content">
      <div className="column is-10">
        <div className="columns is-centered">
							<div className="column is-12">
								<div className="card terms-card">
									<div className="card-header has-text-centered">
										<img src={TermsDog} alt="Dog illustration" />
										<p className="has-text-weight-bold is-size-4">Terms and conditions</p>
										<img src={TermsCat} alt="Cat illustration" />
									</div>
									<div className="card-content">
										<p className="has-text-weight-bold"><strong>PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS, A CLAUSE THAT GOVERNS THE JURISDICTION AND VENUE OF DISPUTES, AN AGREEMENT TO ARBITRATE ON AN INDIVIDUAL BASIS IN THE USA (UNLESS YOU OPT OUT), AND OBLIGATIONS TO COMPLY WITH APPLICABLE LAWS AND REGULATIONS.</strong></p>
										<p className="p2">&nbsp;</p>
										<p className="p1">RoamingTails LLC (also referred to as &ldquo;RoamingTail&rdquo;, &ldquo;RT&rdquo;, &ldquo;roamingtails.com&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;our&rdquo;) maintains the website www.roamingtails.com (known as the &ldquo;Site&rdquo;), and mobile application &ldquo;RoamingTails&rdquo;, (known as the &ldquo;App&rdquo;), which provides a number of services to pet owners (&ldquo;Pet Parents&rdquo; also referred to as &ldquo;Users&rdquo;) and pet friendly establishments. RT also offers a Pet Tag Technology (&ldquo;RoamingTail&rdquo;) that displays the location of a tag when scanned. By using the Site/App, you agree to comply with and be legally bound by the following Terms of Service (&ldquo;Terms&rdquo;), whether or not you become a registered user of the Site. When using our Services, you will also be subject to and bound by the following agreements and policies in our Privacy Policy. If you do not agree</p>
										<p className="p1">to these Terms, you have no right to obtain, use or continuing using information from this Site or our Services.</p>
										<p className="p2">&nbsp;</p>
										<p className="p1">Our Services</p>
										<p className="p2">&nbsp;</p>
										<p className="p1">RoamingTails provides a combination of pet tag technology, mobile application and website to provide a number of services to Pet</p>
										<p className="p1">Parents and pet friendly establishments. The RT tag serves as an information storage device that also displays the tag&rsquo;s location as</p>
										<p className="p1">well as stores vital information such as the pet&rsquo;s name, profile, and medical records. The tag&rsquo;s transmissions can be received by</p>
										<p className="p1">individuals and establishments connected to the RoamingTails network through the mobile application. The mobile App and Website</p>
										<p className="p1">can also be used to find pet related services, including locating hotel rooms, finding pet friendly restaurants and other pet friendly</p>
										<p className="p1">services. Pet friendly businesses in Our network can use RT technology to transmit information, such as service offerings.</p>
										<p className="p1">Eligibility to use Our Site</p>
										<p className="p1">The Site is intended for people who are 18 years of age or older. Any access to our use of the Site by anyone under 18 is prohibited.</p>
										<p className="p1">You must not be using our Site for reasons that are in competition with RT.</p>
										<p className="p1">Registrations Process</p>
										<p className="p1">Individuals who wish to be a member of the RT network must complete a registration form, through which they must provide true,</p>
										<p className="p1">accurate and current information. All Users must indicate that they agree to and will abide by our Terms. Use of this Site and its</p>
										<p className="p1">Services will be deemed as agreement to its Terms as well.</p>
										<p className="p1">All personal information RT collects is governed by our Privacy Policy. Use of our Services by any potential User shall be at the sole</p>
									</div>
								</div>
								<label className="checkbox" style={{margin: "24px 0"}}>
								  <input type="checkbox" id="terms" />
								  I agree to the Terms and Conditions
								</label>
								{/* <div className="has-text-danger terms-error" style="display: none;" >You have to agree to our Terms and Conditions</div> */}
							</div>
						</div>
      </div>
    </div>
  );
}

export default Step4;
