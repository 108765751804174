import React from 'react';

const Step3 = (props) => {

  return (
    <div className={props.step===3?"columns is-centered tab-content active":"columns is-centered tab-content"} id="tab1-content">
      <div className="column is-10">
        <div className="columns is-multiline">
					<div className="column is-6">
						<div className="field">
							<label className="label">Date of Rabies Certification</label>
							<div className="control">
								<input className="input" type="date" id="certDate" placeholder="26 July 2019" defaultValue="2019-07-30" />
							</div>
						</div>
					</div>
					<div className="column is-6"></div>

					<div className="column is-6 ">
						<div className="field">
							<label className="label">Rabies Certification</label>
							{/* <img id="file-preview"  style="max-height: 200px;"/> */}

							<div className="control digital-certification">
								<div className="file is-boxed is-fullwidth has-text-centered">
								  <label className="file-label">
								    <input className="file-input" name="resume" id="file-upload" />
								    <span className="file-cta">
								      <span className="file-icon has-opacity-65">
												<svg className="is-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
												    <path d="M20 8.94a1.31 1.31 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19h-.09a.88.88 0 0 0-.33-.11H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V9v-.06zm-6-3.53L16.59 8H15a1 1 0 0 1-1-1zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3z"/>
												</svg>
								      </span>
								      <span className="file-label has-opacity-65">
								        Choose a file…
								      </span>
								    </span>
								  </label>
								</div>
							</div>
						</div>
						<label className="checkbox">
						  <input type="checkbox" id="mail-cert" />
						  I will mail in physical Certification
						</label>
						<div className="field mailing-address" style={{marginTop: "16px"}}>
							<p>You will have to send the Rabies Certification in <b>maximum 3 days</b> at this address:</p>

							<p className="has-text-weight-bold" style={{marginTop: "16px"}}>
								<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" style={{marginLeft: "-20px", height: "16px"}}>
							    <path d="M8 12h2a1 1 0 0 0 0-2H8a1 1 0 0 0 0 2zm9-6h-5V4h1a1 1 0 0 0 0-2h-2a1 1 0 0 0-1 1v3H7a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h6v4a1 1 0 0 0 2 0v-4h8a1 1 0 0 0 1-1v-6a4 4 0 0 0-4-4zm-4 4v5H5v-5a2 2 0 0 1 2-2h6.56a3.91 3.91 0 0 0-.56 2zm6 5h-4v-5a2 2 0 0 1 4 0z"/>
								</svg> 2130 Van Buren Street #202, Hollywood, FL 33020</p>
						</div>
					</div>
					<div className="column is-6 is-flex is-flex-column is-justified-center digital-certification">
						<p className="is-size-7 has-opacity-65" style={{marginBottom: "8px"}}>
							<svg className="is-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
						    <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"/>
							</svg>
							We have to verify that your pet’s rabies certificate is valid in order to register your pet.
						</p>
						<p className="is-size-7 has-opacity-65" style={{marginBottom: "8px"}}>
							<svg className="is-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
						    <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"/>
							</svg>
							Your file size must not exceed 5MB.
						</p>
						<p className="is-size-7 has-opacity-65" style={{marginBottom: "8px"}}>
							<svg className="is-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
						    <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"/>
							</svg>
							Accepted formats: .pdf, .jpg
						</p>
					</div>

					<div className="column is-6">
						<div className="field">
							<label className="label">1 year or 3 years rabies vaccination</label>
							<div className="control">
								<div className="select is-fullwidth is-primary" >
									<select id="certTime" defaultValue="1">
										<option value="1">1 year</option>
										<option value="3">3 years</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className="column is-6"></div>
				</div>
      </div>
    </div>
  );
}

export default Step3;
